import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import '../styles/News.css';
import { Link } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';

function News()
{
    window.scrollTo(0,0);
    const windowSize = useRef([window.innerWidth, window.innerHeight]);
    const [NewsBody, setNewsBody] = useState('NewsBody');
    const [NewsBodyRow, setNewsBodyRow] = useState('NewsBodyRow');
    const [NewsPublications, setNewsPublications] = useState('NewsPublications');
    const [NewsDates, setNewsDates] = useState('NewsDates');
    const [NewsDatesTitel, setNewsDatesTitel] = useState('NewsDatesTitel');
    const [NewsPublicationsTitel, setNewsPublicationsTitel] = useState('NewsPublicationsTitel');
    const [NewsPubRow, setNewsPubRow] = useState('NewsPubRow');
    const [NewsDatesDate, setNewsDatesDate] = useState('NewsDatesDate');
    const [NewsDateText, setNewsDateText] = useState('NewsDateText');
    const [Feed1, setFeed1]= useState('Feed1');
    const [Feed2, setFeed2]= useState('Feed2');
    const [Feed_TextArea, setFeed_TextArea] = useState('Feed_TextArea');
    const [Feed_Text, setFeed_Text] = useState('Feed_Text');
    const [Feed2_Text, setFeed2_Text] = useState('Feed2_Text');
    const [Feed_Titel, setFeed_Titel] = useState('Feed_Titel');
    const [Feed2_Titel, setFeed2_Titel] = useState('Feed2_Titel');
    const [Feed_Date, setFeed_Date] = useState('Feed_Date');
    const [Feed2_Date, setFeed2_Date] = useState('Feed2_Date');
   

    useEffect(()=>{
        if(windowSize.current[0] < 600)
        {
            setNewsBody('NewsBody_Mobile');
            setNewsBodyRow('NewsBodyRow_Mobile');
            setNewsPublications('NewsPublications_Mobile');
            setNewsDates('NewsDates_Mobile');
            setNewsDatesTitel('NewsDatesTitel_Mobile');
            setNewsPublicationsTitel('NewsPublicationsTitel_Mobile');
            setNewsPubRow('NewsPubRow_Mobile');
            setNewsDatesDate('NewsDatesDate_Mobile');
            setNewsDateText('NewsDateText_Mobile');
            setFeed1('Feed1_Mobile');
            setFeed2('Feed2_Mobile');
            setFeed_TextArea('Feed_TextArea_Mobile');
            setFeed_Text('Feed_Text_Mobile');
            setFeed2_Text('Feed_Text_Mobile');
            setFeed_Titel('Feed_Titel_Mobile');
            setFeed2_Titel('Feed_Titel_Mobile');
            setFeed_Date('Feed_Date_Mobile');
            setFeed2_Date('Feed_Date_Mobile');
        }

    }, windowSize.current[0])

    
    return(
        <div className='NewsWrapper'>
            <Navigation></Navigation>
            <div className='SiteBackground'></div>
            <div className={NewsBody}>
                <div className={NewsBodyRow}>
                    <div className={NewsPublications}>
                        <div className={NewsPublicationsTitel}>Publikationen in der Vorbereitung</div>
                        <div className={NewsPubRow}>
                            Helm, Gerrit (in Produktion): <i>Diagnostik hierarchieniedriger Leseprozesse aus linguistischer Perspektive.</i> [Dissertation]. Peter Lang. 
                        </div>

                        <div className={NewsPubRow}>
                            Helm, Gerrit & Hesse, Florian (eingereicht, Review): Umgang mit Dialekt im Deutschunterricht: Wo bleibt die Sprachbetrachtung?
                        </div>

                        <div className={NewsPubRow}>
                        Dube, Juliane & Helm, Gerrit (eingereicht, Peer-Review): “Und dadurch fühlt man, sag ich jetzt mal, die Geschichte dazu”– Ästhetische Erfahrungen im Umgang mit Bilderbuch-Apps. In: <i>MiDu </i>(Sonderausgabe zur Jahrestagung der AG Medien, hrsg. v. Jan Boelmann, Lisa König und Jaron Müller)
                        </div>

                        <div className={NewsPubRow}>
                        Helm, Gerrit & Hesse, Florian (eingereicht, Peer-Review): Training programmes on writing with AI – but for whom? Identifying students’ writer profiles through two-step cluster analysis.
                        </div>

                        <div className={NewsPubRow}>
                        Helm, Gerrit (eingereicht): Professionelle Wahrnehmung von Lernendentexten als Voraussetzung für die Reflexion KI-generierten Feedbacks. In: <i>Leseräume</i>.
                        </div>


                    </div>
                    <div className={NewsDates}>
                        <div className={NewsDatesTitel}>Termine:</div>

                        <div className='NewsDatesRow'>
                            <div className={NewsDatesDate}>13.02.2025</div>
                            <div className={NewsDateText}>
                                <div className='NewsDateTextH1'>9. Arbeitstreffen der AG Leseverstehen</div>
                                <div className='NewsDateTextP'>Regensburg</div>
                                <a href='' className='Link2'>Informationen</a>
                            </div>
                        </div>

                        <div className='NewsDatesRow'>
                            <div className={NewsDatesDate}>27.03.2025</div>
                            <div className={NewsDateText}>
                                <div className='NewsDateTextH1'>39. Jahrestagung der AG Medien</div>
                                <div className='NewsDateTextP'>Bremen</div>
                                <a href='https://www.uni-bremen.de/fb-10/39-jahrestagung-der-ag-medien' className='Link2'>Informationen</a>
                            </div>
                        </div>
                        
                    </div>

                </div>

                <div className={NewsBodyRow}>
                    <div className='NewsFeed'>
                        <div className={NewsPublicationsTitel}>Newsfeed:</div>

                        <Link to="https://www.friedrich-verlag.de/friedrich-plus/sekundarstufe/deutsch/sprachgebrauch/ki-im-literaturunterricht-19740" style={{color:"black"}}>
                        <div className={Feed2}>
                            <div className={Feed_TextArea}>
                                <div className={Feed2_Date}>18.12.2024</div>
                                <div className={Feed2_Titel}>KI im Literaturunterricht: Neuer Artikel in <i>Der Deutschunterricht</i> </div>
                                <div className={Feed2_Text}>Gemeinsam mit <a href="https://marcomagirius.de/" style={{color: "darkblue"}}>Marco Magirius</a>, <a href="https://florian-hesse.com/"  style={{color: "darkblue"}}>Florian Hesse</a> und Daniel Scherf habe ich einen Beitrag für <i>Der Deutschunterricht</i> verfassen dürfen, in dem wir zwei Jahre nach der öffentlichen Zugänglichmachung von ChatGPT im November 2022 ein erstes Resümee zum Einsatz von KI im Literaturunterricht ziehen. </div>
                                <div className={Feed2_Text}>Konkret schauen wir uns an, inwieweit KI-Anwendungen als Verstehenshilfe beim Lesen literarischer Texte, als Schreibpartner beim Verfassen von Interpretationsaufsätzen und als Dialogpartner beim Sprechen über Literatur fungieren kann. Für alle Bereiche fragen wir uns, inwieweit der Einsatz von KI eine <i>Komplexitätsreduktion</i> oder <i>Komplexitätssteigerung</i> herbeiführt.</div>
                                <div className={Feed2_Text}>Eine Leseprobe des Beitrags findet sich auf der Seite des Friedrich Verlags.</div>
                            </div>
                            <div className='FeedPic_KI_DerDeutschunterricht'></div>
                        </div>
                        </Link>

                        <Link to="https://www.tandfonline.com/doi/full/10.1080/21532974.2024.2431747?scroll=top&needAccess=true" style={{color:"black"}}>
                        <div className={Feed1}>
                            <div className='FeedPic_JDLTE1'></div>
                            <div className={Feed_TextArea}>
                                <div className={Feed_Date}>9.12.2024</div>
                                <div className={Feed_Titel}>Neuer internationaler Beitrag: Writing with AI in and beyond teacher education: Exploring subjective training needs of student teachers across five subjects</div>
                                <div className={Feed_Text}>Im Rahmen unseres Projektes <b>Schreiben mit KI im Lehramt</b> haben <a href="https://florian-hesse.com/" style={{color:"darkblue"}}>Florian Hesse</a> und ich einen zweiten englischsprachigen Beitrag verfasst, der nun im <i>Journal of Digital Learning in Teacher Education</i> erschienen ist.</div>
                                <div className={Feed_Text}>Der Beitrag nimmt sich einer gegenwärtig höchst relevanten Fragestellung an, nämlich welche Fortbildungsbedarfe unter Lehramtsstudierenden im Bereich KI bestehen. In den Fokus nehmen wir hierbei vor allem die <b>subjektiven Bedürfnisse</b> der Lehramtsstudierenden, also jene Bedarfe, die sie selbst bei sich sehen. Dazu haben wir 505 Lehramtsstudierende der FSU Jena um Rückmeldungen in Freitextform gebeten, die wir mit Qualitativer Inhaltsanalyse ('QCA') ausgewertet haben. Der Beitrag kann hier nachgelesen werden.</div>
                            </div>
                        </div>
                        </Link>

                        <div className={Feed2}>
                            <div className={Feed_TextArea}>
                                <div className={Feed2_Date}>28.11.2024</div>
                                <div className={Feed2_Titel}>Workshop zum Thema Schulentfremdung gemeinsam mit dem ThILLM</div>
                                <div className={Feed2_Text}>Schulentfremdung ist keine 'neue', sondern eine bestehende Herausforderung: Den Zahlen einschlägiger Forschungsberichten folgend sind 10% aller Lernenden an unseren Schulen entweder von den Lehrkräften, den Klassenkamerad:innen oder dem Lernen insgesamt entfremdet. Ursache hierfür ist das Zusammenspiel zahlreicher Faktoren, zu denen auch die Unterrichtsqualität zählt.</div>
                                <div className={Feed2_Text}>Florian Hesse und ich waren eingeladen, in einem Workshop für Fachberater:innen zu erarbeiten, wie mit diesen Herausforderungen im (Sprach-)Unterricht umgegangen werden kann.</div>
                            </div>
                            <div className='FeedPic_Entfremdung'></div>
                        </div>

                        <div className={Feed1}>
                            <div className='FeedPic_Zukunfstforum_Karlsruhe'></div>
                            <div className={Feed_TextArea}>
                                <div className={Feed_Date}>15.11.2024</div>
                                <div className={Feed_Titel}>Keynote auf dem Zukunftsforum Bildungsforschung</div>
                                <div className={Feed_Text}>Am 14.11. und 15.11.2024 trafen sich wissenschaftliche Nachwuchsforschende der Graduiertenakademie der PH Karlsruhe unter dem Titel <b>Transformation(en) in Schule und Unterricht – Wandel reflektieren, gestalten und evaluieren</b> für ein Zukunftsforum. Ziel dieser Tagung war es, Doktorand:innen ein Format zu bieten, das es erlaubt, eigene Forschungsprojekte auf aktuelle bildungspolitische und bildungswissenschaftliche Themen zu beziehen. </div>
                                <div className={Feed_Text}>Gemeinsam mit Prof. Dr. Kirsten Schindler und Alyssa Kutzner (beide von der Uni Wuppertal) durfte ich die Keynote der Veranstaltung gestalten. Wir präsentierten einen Überblick zu Erkenntnissen zum Schreiben mit KI von der Grundschule, über die weiterführende Schulen bis hin zum Schreiben mit KI im Lehramtsstudium.</div>
                            </div>
                        </div>

                        <div className={Feed2}>
                            <div className={Feed_TextArea}>
                                <div className={Feed2_Date}>5.11.2024</div>
                                <div className={Feed2_Titel}>Vortrag auf der Jahrestagung 2024 des eTeach-Netzwerks Thüringen</div>
                                <div className={Feed2_Text}>Gemeinsam mit Florian Hesse hatte ich Möglichkeit, auf der diesjährigen Jahrestagung des eTeach-Netzwerks einen Impulsvortrag beitragen zu dürfen. Der Zeitpunkt des Vortrag fiel beinahe exakt mit dem 2. Jubiläum der Veröffentlichung von <i>ChatGPT</i> zusammen. Dies nahmen wir zum Anlass, um zurückzublicken, welche Entwicklung das Schreiben an der Hochschule in den vergangenen zwei Jahren genommen hat. Hierzu berichteten wir aus den zahlreichen Studien, die wir zum Schreiben mit KI an der FSU Jena durchgeführt haben.</div>
                                <div className={Feed2_Text}>Der gesamte Vortrag wurde durch das Video-Team des eTeach-Netzwerks aufgezeichnet und kann (in Kürze) hier abgerufen werden.</div>
                            </div>
                            <div className='FeedPic_eTeach_Jahrestagung'></div>
                        </div>

                        <div className={Feed1}>
                            <div className='FeedPic_SDD_Plenum'></div>
                            <div className={Feed_TextArea}>
                                <div className={Feed_Date}>18.09.2024</div>
                                <div className={Feed_Titel}>Teilnahme am 25. Symposion Deutschdidaktik an der Johannes Gutenberg Universität</div>
                                <div className={Feed_Text}>Vom 15.09. bis 18.09.2024 fand das 25. Symposion Deutschdidaktik an der Johannes Gutenberg Universität in Mainz statt. In 3 Plenarvorträgen, 15 Sektionen, 13 Panels und auf rund 40 Postern hatten die über 600 Teilnehmenden die Möglichkeit, sich zu unterschiedlichen deutschdidaktischen Themen auszutauschen. Besonderes Highlight für unser Jenaer Team war dabei der Preisträgervortrag von <a href="https://florian-hesse.com/foerderpreis-deutschdidaktik/" style={{color:"blue"}}>Florian Hesse</a> am Montagabend.</div>
                                <div className={Feed_Text}>Ich selbst durfte am Mittwoch im Rahmen eines Sektionsvortrags einen Einblick in unser Projekt <i>Schreiben mit KI</i> geben, erste Resultate aus den Fortbildungsmodulen präsentieren und auf ein schreibdidaktisches Desiderat hinweisen.</div>
                            </div>
                        </div>

                            <div className={Feed2}>
                            <div className={Feed_TextArea}>
                                <div className={Feed2_Date}>16.09.2024</div>
                                <div className={Feed2_Titel}>Posterpräsentation auf dem SDD 2024 in Mainz</div>
                                <div className={Feed2_Text}>In der Postersektion auf dem SDD 2024 an der Johannes Gutenberg Universität in Mainz stellte ich eine aktualisierte Variante des Posters zu meiner Lesediagnose-Anwendung <i>AiDe-L</i> vor. Los ging es mit dieser Sektion am Montag um 17.45 Uhr. Interessierte können sich mein Poster gern <a href="/SDD_Poster_2024" style={{color:"blue"}}>hier</a> anschauen und herunterladen.</div>
                                <div className={Feed2_Text}>In der Vorbereitung der Postersektion waren alle Teilnehmenden aufgefordert, ein kurzes Teaser-Video von nur wenigen Sekunden herzustellen. Die Umsetzung war am Ende doch eine kleine Herausforderung. Das fertige Produkt ist <a href="https://youtu.be/nZBbavqe9tk" style={{color:"blue"}}>hier</a> zu sehen. Ich freue mich auf viele interessante Gespräche am Poster.</div>
                            </div>
                            <div className='FeedPic_Poster_SDD_2024'></div>
                        </div>

                        <Link to="https://sciendo.com/de/article/10.2478/ristal-2024-0001" style={{color:"black"}}>
                        <div className={Feed1}>
                            <div className='FeedPic_RISTAL'></div>
                            <div className={Feed_TextArea}>
                                <div className={Feed_Date}>6.07.2024</div>
                                <div className={Feed_Titel}>Internationaler Beitrag: Usage and beliefs of student teachers towards artificial intelligence in writing</div>
                                <div className={Feed_Text}>Im Rahmen unseres Projektes <Link to="https://www.schreibenmitki.de" style={{color:"black"}}><b>Schreiben mit KI im Lehramt</b></Link> haben Florian Hesse und ich eine Online-Befragung unter sämtlichen Lehramtsstudierenden der FSU Jena durchgeführt. Insgesamt haben sich dankenswerterweise 505 Lehramtsstudierende aus fünf unterschiedlichen Fächern beteiligt, womit uns eine große Datenbasis zur Verfügung steht.</div>
                                <div className={Feed_Text}>In einer ersten Studie haben wir uns einerseits angeschaut, <i>welche</i> KI-Anwendungen Lehramtsstudierende kennen und nutzen und für <i>welche Schreibphasen</i> sie dies tun. Anderseits haben wir betrachtet, inwieweit Lehramtsstudierende glauben, dass generative KI das Schreiben in der Schule verändern wird.</div>
                                <div className={Feed_Text}>Unser englischsprachiger Artikelbeitrag kann in <Link to="https://sciendo.com/de/article/10.2478/ristal-2024-0001" style={{color:"black"}}><b>Research in Subject-matter Teaching and Learning (RISTAL)
                                </b></Link> nachgelesen werden.                                </div>
                            </div>
                        </div>
                        </Link>

                        <div className={Feed2}>
                            <div className={Feed_TextArea}>
                                <div className={Feed2_Date}>2.07.2024</div>
                                <div className={Feed2_Titel}>Erste Präsentation des Lese-Lern-Spiels <i>Wort Ag-Enten</i> auf der Di.ge.LL-Tagung in Freiburg</div>
                                <div className={Feed2_Text}>Eines der zentralen Themen der diesjährigen <b>di.ge.LL</b>-Tagung vom 1.07. bis 2.07. an der Pädagogischen Hochschule Freiburg war exploratives und selbstgesteuertes Lernen in Computerspielen. Ich nahm dies zum Anlass, eine erste Entwicklungsstufe meines browserbasiertes Lese-Lern-Spiels <i>Wort-Ag-Enten</i> vorzustellen, an dem ich hinter den Kulissen gearbeitet habe.</div>
                                <div className={Feed2_Text}>Ziel ist die Entwicklung eines Lernspiels, das auf der einen Seite eine sprachsystematische Leseförderung bietet, die nicht nur Einzelebenen und Einzelphänomene fördert, sondern vor allem den Übergang zwischen mehreren Ebenen des Lesens anbahnt. Dies wird auf der anderen Seite mit einem bekannten digitalen Spielprinzip kombiniert.</div>
                            </div>
                            <div className='FeedPic_NEST'></div>
                        </div>

                        <div className={Feed1}>
                            <div className='FeedPic_PostDocAkademie'></div>
                            <div className={Feed_TextArea}>
                                <div className={Feed_Date}>22.06.2024</div>
                                <div className={Feed_Titel}>1. PostDoc-Akademie des SDD an der Uni Jena</div>
                                <div className={Feed_Text}>Mit dem Abschluss der Dissertation beginnt für Jungwissenschaftler:innen eine neue (Karriere-)Phase mit z.T. neuen und z.T. veränderten Anforderungen und Herausforderungen.</div>
                                <div className={Feed_Text}>Vor diesem Hintergrund haben Florian Hesse und ich Post-Docs aus dem gesamten deutschsprachigen Raum eingeladen, mit uns gemeinsam eine 1. Postdoc-Akademie abzuhalten. Wir konnten uns über 12 wunderbare Gäste und mit Anita Schilcher, Michael Steinmetz und Melanie Bangel drei eindrückliche Keynotes freuen.</div>
                                <div className={Feed_Text}>Einen ausführlichen Bericht zur Akademie verfasst Hendrick Heimböckel, der zeitnah <i>hier</i> nachzulesen ist.</div>
                            </div>
                        </div>

                        <Link to="https://www.schreibenmitki.de" style={{color:"black"}}><div className={Feed2}>
                            <div className={Feed_TextArea}>
                                <div className={Feed2_Date}>18.06.2024</div>
                                <div className={Feed2_Titel}>Erprobung des ersten Workshop-Modul im Projekt "Schreiben mit KI" erfolgreich.</div>
                                <div className={Feed2_Text}>Mit gleich zwei Durchläufen erfreute sich das erste Workshop-Modul "Feedback mit KI" im Projekt "Schreiben mit KI im Lehramt" großer Nachfrage von Seiten der Studierenden. An jeweils zwei Workshop-Tagen betrachteten wir zunächst Merkmale 'guter' Texte und Textualitätskriterien, anschließend trugen wir Merkmale lernförderlichen Feedbacks zusammen und stellten uns abschließend die Frage, inwieweit KI-Anwendungen vor dem Hintergrund dieser Anforderung bereits eine Ressource sein können (und für wen und wann).</div>
                                <div className={Feed2_Text}>Ich kann nur den allergrößten Dank an zwei sehr engagierte und motivierte Workshop-Gruppen richten, die mir in den Veranstaltungen sehr viel Freude bereitet haben.</div>
                            </div>
                            <div className='FeedPic_WorkshopFeedback'></div>
                        </div></Link>

                        <Link to="https://www.kinderundjugendmedien.de/kritik/computerspiele/7152-rundisc-chants-of-sennar" style={{color:"black"}}>
                        <div className={Feed1}>
                            <div className='FeedPic_Chants'></div>
                            <div className={Feed_TextArea}>
                                <div className={Feed_Date}>28.05.2024</div>
                                <div className={Feed_Titel}>Rezension zu "Chants of Sennaar" (Nintendo Switch)</div>
                                <div className={Feed_Text}>Gemeinsam mit Florian Hesse haben wir uns an einem für beide neuem Format erprobt: Der Rezension eines Computer- bzw. Switch-Spiels für <i>KinderundJugendMedien.de</i>. </div>
                                <div className={Feed_Text}>Das Spiel selbst lässt in meinen Augen das Sprachdidakter:innenherz sofort höher schlagen: In einer am Turmbau zu Babel orientierten Geschichte, wird die Rolle einer Spielfigur übernommen, die zwischen verschiedenen Kulturen sprachlich vermitteln muss. Dies versetzt Spielende in die Situation, gleich mehrere (Fantasie-)Sprachen erlernen und diese im Spiel gezielt einsetzen zu müssen. </div>
                                <div className={Feed_Text}>Die (Spiel-)Sprachen sind dabei überaus systematisch konzipiert, die Spielautor:innen schreckten auch nicht davor zurück, sogar morphologische Phänomene wie Pluralbildung in das Set der Regularitäten zu integrieren. Hier findet sich viel Potenzial für sprachlich-literarisches Lernen.</div>
                            </div>
                        </div>
                        </Link>

                        <Link to="https://www.medienpaed.com/article/view/1616" style={{color:"black"}}><div className={Feed2}>
                            <div className={Feed_TextArea}>
                                <div className={Feed2_Date}>16.03.2024</div>
                                <div className={Feed2_Titel}>Neuer Zeitschriftenbeitrag: "Hingeschaut – Bilderbuch-Apps zum literarischen Lernen im inklusiven Deutschunterricht"</div>
                                <div className={Feed2_Text}>Von Bilderbuch-Apps (was auch immer darunter genau verstanden wird) scheint eine gewisse Faszination auszugehen: Die Download-Zahlen dieser Apps in den Stores steigen stetig und auch im Nachgang meiner Seminare wollen immer wieder Studierende zu diesem Thema forschen. Jedoch sind bei Weitem nicht alle Bilderbuch-Apps als hochwertig zu bezeichnen.</div>
                                <div className={Feed2_Text}>Juliane Dube und ich haben deshalb gemeinsam einen Kriterienkatalog entwickelt und erprobt, der einladen soll, die Qualität einer vorgefundenen Bilderbuch-App zu reflektieren. Den begleitenden Artikelbeitrag findet man auf der Seite der Medien Pädagogik, den Katalog selbst in Kürze <i>hier</i>.</div>
                            </div>
                            <div className='FeedPic_Hingeschaut'></div>
                        </div></Link>

                        <div className={Feed1}>
                            <div className='FeedPic_8AGLesen'></div>
                            <div className={Feed_TextArea}>
                                <div className={Feed_Date}>23.02.2024</div>
                                <div className={Feed_Titel}>Teilnahme am 8. Arbeitstreffen der AG Leseverstehen in Koblenz</div>
                                <div className={Feed_Text}>Angesichts des stetig schwachen Abschneidens im Bereich Lesen der deutschsprachigen Schüler:innen in den internationalen Vergleichsstudien (PISA 2022, IGLU 2016, etc.) trafen sich die Mitglieder der AG Leseverstehen von 22. bis 23.02.2024 an der Uni Koblenz, um Lesedidaktik, Lesediagnostik und Leseförderung in einen systemischen Zusammenhang mit der schulischen Praxis zu stellen. Besonders im Fokus standen hierbei u.a. die in vielen Bundesländern (z.T. kurzfristig) eingerichteten 'Lesebänder'. Auf dem Programm fanden sich 4 Vorträge am ersten Tag und eine Posterpräsentation sowie eine umfangreiche Diskussionsrunde am zweiten Tag. Ich habe den Anlass genutzt, um mein Lesediagnoseverfahren AiDe-L in einem Poster vorzustellen, das <a href="https://www.researchgate.net/publication/378434648_AiDe-L_Browser-Anwendung_zur_individuellen_Diagnose_im_Lesen_Poster_zum_8_Arbeitstreffen_der_AG_Leseverstehen_in_Koblenz" style={{color:"darkblue"}}>hier</a> angesehen werden kann.</div>
                               
                            </div>
                        </div>

                        <Link to="https://link.springer.com/chapter/10.1007/978-3-662-67816-9_9" style={{color:"black"}}><div className={Feed2}>
                            
                            <div className={Feed_TextArea}>
                                <div className={Feed2_Date}>14.02.2024</div>
                                <div className={Feed2_Titel}>Neuer Artikelbeitrag: "kluug das Lesen fördern. Digitale systematische Leseförderung für die Grundschule"</div>
                                <div className={Feed2_Text}>In der Zeit von Sommer 2021 bis zum Herbst 2023 begleiteten Steffen Gailberger und ich das Hamburger Start-Up <i>LEAD Lernen digital</i> und entwickelten eine Reihe von Konzepten für lesedidaktische digitale Spiele. Eine Vielzahl an Ideen übernahmen oder adaptierten die Spielemacher:innen für ihre Platt <i>kluug</i>.</div>
                                <div className={Feed2_Text}>Im Rahmen eines Artikelbeitrags in der Festschrift für Cornelia Rosebrock haben Steffen Gailberger und ich die Grundkonzepte dieser Spiele, die eine systematische digitale Leseförderungen erlauben sollen, vorgestellt.</div>
                            </div>
                            <div className='FeedPic_kluug'></div>
                        </div></Link>

                        <Link to="/Artikel_Trialog" style={{color:"black"}}>
                        <div className={Feed1}>
                            <div className='FeedPic_Trialog'></div>
                            <div className={Feed_TextArea}>
                                <div className={Feed_Date}>28.01.2024</div>
                                <div className={Feed_Titel}>Trialog Bildung 2035</div>
                                <div className={Feed_Text}>Am 25.01.2024 trafen sich Mitwirkende aus der Bildungsadministration, der fachdidaktischen Forschung und der Schulpraxis zum Trialog in den Räumlichkeiten der Robert-Bosch-Stiftung. Ziel war es, sich über Möglichkeiten auszutauschen, wie in KiTas und Schulen bis zum Jahr 2035 flächendeckend qualitativ hochwertige Bildung und Chancengleichheit umgesetzt werden können.</div>
                                <div className={Feed_Text}>Gemeinsam mit den Grundschullehrkräften Sina Karschöldgen und Stefan Jänen von der Grundschule Bogenstraße in Solingen gestaltete ich eine Präsentation zur Textüberarbeitung mit KI. Lest hier meinen Bericht zum Tag.</div>
                            </div>
                        </div>
                        </Link>

                        <Link to="https://didaktikdeutsch.de/schreiben_mit_ki/" style={{color:"black"}}><div className={Feed2}>
                            
                            <div className={Feed_TextArea}>
                                <div className={Feed2_Date}>17.01.2024</div>
                                <div className={Feed2_Titel}>Start des Projektes "Schreiben mit KI im Lehramt"</div>
                                <div className={Feed2_Text}>Gemeinsam mit meinem Projektpartner Florian Hesse und unter der Gesamtleitung von Iris Winkler starteten wir in das Projekt <i>Schreiben mit KI im Lehramt</i>. </div>
                                <div className={Feed2_Text}>Ziel des Projektes ist die durch empirische Forschung begleitete Entwicklung von Lehr-Lern-Modulen, die Lehramtsstudierende sämtlicher Fächer als Selbstlernkurse durchlaufen. Die Module sollen die Studierenden darin unterstützen, den sich gegenwärtig durch technischen Fortschritt wandelnden Anforderungen sowohl an das Schreiben im Studium als auch das Schreiben in der Schule zu begegnen.</div>
                                <div className={Feed2_Text}>Weitere Informationen sind über die Internetseite der Uni Jena abzurufen und folgen  in Kürze auch hier.</div>
                            </div>
                            <div className='FeedPic_WritingAI'></div>
                        </div></Link>
                        
                       

                    </div>
                </div>

            </div>
            <Footer></Footer>
        </div>
    )
}
export default News;