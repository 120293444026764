import '../styles/Projekte.css'
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import { Link } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';

function Projekte()
{
    window.scrollTo(0,0);
    const windowSize = useRef([window.innerWidth, window.innerHeight]);
    const [ProjekteBody, setProjekteBody] = useState('ProjekteBody');
    const [Projekt, setProjekt] = useState('Projekt');
    const [ProjektTextField, setProjektTextField] = useState('ProjektTextField');
    const [ProjekteTitel, setProjekteTitel] = useState('ProjekteTitel');
    const [ProjekteUntertitel, setProjekteUntertitel] = useState('ProjekteUntertitel');
    const [ProjektSektion, setProjektSektion] = useState('ProjektSektion');

    useEffect(()=>{
        if(windowSize.current[0] < 600)
        {
            setProjekteBody('ProjekteBody_mobile');
            setProjekt('Projekt_mobile');
            setProjektTextField('ProjektTextField_mobile');
            setProjekteTitel('ProjekteTitel_mobile');
            setProjekteUntertitel('ProjekteUntertitel_mobile')
        }

    }, windowSize.current[0])

    return(
        <div className='ProjekteWrapper'>
            <Navigation></Navigation>
            <div className='SiteBackground'></div>
            <div className={ProjekteBody}>
                <Link to="https://www.aide-l.de/"><div className={Projekt}>
                    <div className='ProjektPic_Aide'></div>
                    <div className={ProjektTextField}>
                        <div className={ProjekteTitel}>AiDe-L (Dissertationsprojekt)</div>
                        <div className={ProjekteUntertitel}>Anwendung zur individuellen Diagnose im Lesen</div>
                        <div className='ProjektText'>Mit der Anwendung zur individuellen Diagnose im Lesen (AiDe-L) wird ein digitales Lesediagnoseverfahren entwickelt, das in der Lage ist, individuelle Unterschiede in der Verarbeitung von variierenden, textseitigen Merkmalsausprägungen beim Lesen (von Schüler:innen) sichtbar zu machen. Ermöglicht werden soll so eine Lesediagnose, die weitgehend unabhängig vom Vorwissen und Sprachwissen der Schüler:innen ist. Verfügbar gemacht wird dies in Form einer Browser-Anwendung, die Schüler:innen individuell (und ohne Vorbereitung auf Seiten der Lehrkraft) auf einem digitalen Endgerät bearbeiten. Lehrkräfte erhalten eine direkte Rückmeldung zum Lesestand ihrer Schüler:innen, ohne selbst eine Auswertung vornehmen zu müssen.  </div>
                        <div className='ProjektText'>Ziele des Projektes sind die Entwicklung (bereits abgeschlossen), Pilotierung (bereits abgeschlossen) und Normierung des Verfahrens.</div>
                    </div>
                </div></Link>

                <Link to="https://www.schreibenmitki.de"><div className={Projekt}>
                    <div className={ProjektTextField}>
                        <div className={ProjekteTitel}>Schreiben mit KI im Lehramt (Uni Jena)</div>
                        <div className={ProjekteUntertitel}>Entwicklung von Lehr-Lern-Modulen zum Schreiben im Kontext von KI</div>
                        <div className={ProjekteUntertitel}>(gemeinsam mit <a href="https://florian-hesse.com/" style= {{color:"darkblue"}}>Florian Hesse</a>)</div>
                        <div className='ProjektText'>Formulierungen wie "Der Computer schreibt (mit)" (Steinhoff 2023, Titel) oder "Schreiben durch, mit und in digitalen Medien" (Schindler 2023, S. 4) verdeutlichen, dass das Schreiben gegenwärtig eine Transformation erfährt, die v.a. durch technische Entwicklungen und digitalen Fortschritt bedingt ist. Lehramtsstudierende begegnen den sich wandelnden Kontexten des Schreibens gleich doppelt: Als Lernende in einem sich ebenso wandelnden wissenschaftlichen Schreiben im Studium und als zukünftige Lehrende, die Schüler:innen in einem von digitalen Medien geprägten Schreiberwerb begleiten. </div>
                        <div className='ProjektText'>Um diesem zu begegnen, werden Lehr-Lern-Module zum Themenfeld "Schreiben im Kontext von KI" entwickelt (und in Form von Design-Based-Research empirisch evaluiert), die Lehramtsstudierende der Uni Jena studienbegleitend absolvieren.</div>
                    </div>
                    <div className='ProjektPic_KI'></div>
                </div></Link>
               
               <div className={ProjektSektion}>Vorherige Projekte:</div>
               
               <Link to="/">
                <div className={Projekt}>
                    <div className='ProjektPic_Leseband'></div>
                    <div className={ProjektTextField}>
                        <div className={ProjekteTitel}>Leseband_NRW (2022-2024)</div>
                        <div className={ProjekteUntertitel}>Leseförderung bei schwächsten Lesenden in NRW</div>
                        <div className={ProjekteUntertitel}>(gemeinsam mit Steffen Gailberger & Cedric Schmidt)</div>
                        <div className='ProjektText'>Die PISA-Ergebnisse der Erhebung von 2022 machen erneut deutlich, dass die Lesekompetenz deutscher Schüler:innen hinter den Erwartungen zurückbleiben. In besonderer Weise besorgniserregend ist dabei, dass der Anteil 'schwächster' Lesender, die nicht einmal die Minimalstandards erreichen, stetig ansteigt. </div>
                        <div className='ProjektText'>Das durch die <a style={{color:'darkblue'}}href="https://www.auridis-stiftung.de/">Auridis Stiftung</a> geförderte Projekt Leseband_NRW bringt die in Hamburg vielfach positiv evaluierte tägliche Lesezeit von 20 Minuten auch an Schulen in NRW. Projektschulen wurden mit umfangreichen Bücherkisten ausgestattet und die Lehrkräfte im Einsatz von Lautleseverfahren (z.B. Lesen mit dem Ich-Du-Wir-Würfel, Lesetandems...) geschult. Im Interesse der Forschung steht hierbei die Entwicklung der 'schwächsten' Lesenden über einen Zeitraum von drei Jahren.</div>
                    
                    </div>
                </div></Link>

                <Link to="https://www.lernenmitlead.de/"><div className={Projekt}>
                    <div className={ProjektTextField}>
                        <div className={ProjekteTitel}>Leselernplattform <i>kluug</i> (2021-2023)</div>
                        <div className={ProjekteUntertitel}>Systematische Leseförderung mit einer Lernplattform für die Primarstufe</div>
                        <div className={ProjekteUntertitel}>(mit Steffen Gailberger, Laura Reichenbach und Raphaela Rödeler)</div>
                        <div className='ProjektText'>Die Leseleistungen der Lernenden der Grundschulen in Deutschland verbleiben 'schwach', wie z.B. der IQB-Bildungstrend jährlich verdeutlicht. Dies ist insofern bemerkenswert, als die deutschsprachige Lesedidaktik und ebenso die Schulpraxis erhebliche Bemühungen unternommen haben, das Lesen zu fördern. Zu vermuten steht, dass bisherige Lesefördermaßnahmen nicht ausreichen und insbesondere für 'schwächste' Lesende weitere Angebote geschaffen werden müssen.</div>
                        <div className='ProjektText'>Im Rahmen des Projektes wurde vor diesem Hintergrund eine digitale Lese-Lernplattform konzipiert, die Lesende in einer Reihe von interaktiven Lernspielen systematisch und ausgehend vom individuellen Lernstand im Lesen von der Ebene der Buchstabensegmente bis zur Text-Ebene fördert. Das Hamburger Start-Up <i>LEAD Lernen digital</i> betreibt seit einiger Zeit eine Förderplattform auf Basis dieser Konzeptionsarbeiten.</div>
                    </div>
                    <div className='ProjektPic_kluug'></div>
                </div></Link>
                


            </div>
            <Footer></Footer>
        </div>
    )
}
export default Projekte;